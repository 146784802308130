import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { useUpdateOTLds } from '@phx-husky/use-update-ot-lds';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from 'components/Layout';
import Banner from 'components/common/Banner';

import 'styles/main.scss';
import './LdsPage.scss';

const LdsPage: FC<AllLds.Page> = ({
  data: {
    allLds: {
      nodes: [
        {
          seoNoIndex,
          seoMetaTitle,
          seoMetaDescription,
          seoMetaKeywords,
          seoCanonicalUrl,
          seoExternalHreflangs,
          seoImage,
          ogPageType,
          title,
          body,
          dir,
          link,
          versionId,
          platform,
          businessId,
        },
      ],
    },
    siteSettings,
    homepageSettings,
    menu,
    footerNavigation,
    mobileAppPromoBanner,
    languageSelector,
  },
}) => {
  const { updatedLdsContent, isCheckingLdsVersion } = useUpdateOTLds({
    baseUrl: process.env.GATSBY_LDS_BASE_URL as string,
    businessId,
    versionId,
    platform,
  });

  const content =
    updatedLdsContent || body ? (
      <div dangerouslySetInnerHTML={{ __html: updatedLdsContent ?? body }} />
    ) : null;
  const direction = dir && dir[0] && dir[0] !== 'inherit' ? dir[0].toLowerCase() : undefined;

  return (
    <Layout
      seo={{
        seoNoIndex,
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoCanonicalUrl,
        seoExternalHreflangs,
        seoImage,
        ogPageType,
      }}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      languageSelector={languageSelector}
      url={link}
    >
      {!isCheckingLdsVersion && (
        <section className="dt-lds-page" dir={direction}>
          <Banner
            title={{
              regularText: title,
            }}
          />

          <Container fluid>
            <Row>
              <Col lg={{ span: 8, offset: 2 }}>{content}</Col>
            </Row>
          </Container>
        </section>
      )}
    </Layout>
  );
};

export default LdsPage;

export const query = graphql`
  query LdsPageQuery($link: String, $lang: String) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
    }
    allLds(filter: { link: { eq: $link }, lang: { eq: $lang } }) {
      nodes {
        link
        title
        body
        dir
        seoMetaDescription
        seoNoIndex
        seoMetaTitle
        seoMetaKeywords
        seoCanonicalUrl
        seoImage
        ogPageType
        seoExternalHreflangs {
          key
          value
        }
        versionId
        platform
        businessId: businessID
      }
    }
  }
`;
